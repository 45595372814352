import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { zodResolver } from "@hookform/resolvers/zod";

import { updateUserSchema, UpdateUserSchema } from "src/schema/user.schema";
import { User } from "src/lib/user";
import { useEffect } from "react";
import { pick } from "src/lib/util";

type EditUserDialogProps = {
  user: User;
  open: boolean;
  onClose: () => void;
  onConfirm: (user: UpdateUserSchema) => void;
};

const FormValues = ["id", "firstName", "lastName", "username", "role"] as const;

export function EditUserDialog(props: EditUserDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateUserSchema>({
    resolver: zodResolver(updateUserSchema),
    defaultValues: pick(props.user, FormValues),
  });

  useEffect(() => {
    reset(pick(props.user, FormValues));
  }, [props.user, reset]);

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: UpdateUserSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Modifier un employé</DialogTitle>
        <DialogContent>
          <TextField
            id="firstName"
            autoFocus
            margin="normal"
            label="Prénom"
            fullWidth
            variant="outlined"
            defaultValue={props.user.firstName}
            inputProps={register("firstName", { required: true })}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <TextField
            id="lastName"
            margin="normal"
            label="Nom"
            fullWidth
            variant="outlined"
            defaultValue={props.user.lastName}
            inputProps={register("lastName", { required: true })}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
          <TextField
            id="username"
            margin="normal"
            label="Nom d'utilisateur"
            fullWidth
            variant="outlined"
            defaultValue={props.user.username}
            inputProps={register("username", { required: true })}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
          <FormControl
            margin="normal"
            fullWidth
            defaultValue={props.user.role}
            required
          >
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              native={isMobile}
              id="role"
              label="Role"
              labelId="role-label"
              variant="outlined"
              fullWidth
              defaultValue={props.user.role}
              inputProps={register("role", { required: true })}
              sx={{ padding: "12px 10px" }}
              error={!!errors.role}
            >
              {isMobile
                ? [
                    <option key="ADMIN" value="ADMIN">
                      Administrateur
                    </option>,
                    <option key="USER" value="USER">
                      Employé
                    </option>,
                  ]
                : [
                    <MenuItem
                      key="ADMIN"
                      value="ADMIN"
                      sx={{ fontSize: "inherit" }}
                    >
                      Administrateur
                    </MenuItem>,
                    <MenuItem
                      key="USER"
                      value="USER"
                      sx={{ fontSize: "inherit" }}
                    >
                      Employé
                    </MenuItem>,
                  ]}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button color="primary" type="submit" autoFocus>
            Modifier
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
