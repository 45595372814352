import { useState } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useContextMenu } from "src/hooks/useContextMenu";
import { UpdateJobSchema } from "src/schema/job.schema";
import { JobWithWorksite } from "src/lib/job";
import { getSelectableWorksites, Worksite } from "src/lib/worksite";
import { ConfirmDialog } from "./ConfirmDialog";
import { EditJobMenu } from "./EditJobMenu";
import { EditJobDialog } from "./EditJobDialog";
import { durationFormatter } from "src/lib/intl";
import { theme } from "src/lib/theme";

type JobListItemProps = {
  primary: string;
  job: JobWithWorksite;
  worksites: Array<
    Pick<Worksite, "id" | "name" | "number" | "isDescriptionRequired" | "type">
  >;
  hideOptions?: boolean;
  onUpdate: (data: UpdateJobSchema) => void;
  onDelete: (id: number) => void;
};

export function JobListItem(props: JobListItemProps) {
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const contextMenu = useContextMenu();

  return (
    <ListItem
      disablePadding
      onContextMenu={contextMenu.onContextMenu}
      secondaryAction={
        <>
          {props.hideOptions ? null : (
            <IconButton
              edge="end"
              {...contextMenu.getButtonProps()}
              sx={{ marginRight: -0.75 }}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>
          )}
          <EditJobMenu
            menuProps={contextMenu.getMenuProps()}
            onUpdate={() => setUpdateDialogOpen(true)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </>
      }
    >
      <ListItemButton
        sx={{ borderRadius: 0 }}
        onClick={() => setUpdateDialogOpen(true)}
      >
        <ListItemText
          primary={props.primary}
          secondary={
            <>
              {props.job.referenceNumber && (
                <ListItemText secondary={props.job.referenceNumber} />
              )}
              {props.job.address && (
                <ListItemText secondary={props.job.address} />
              )}
              {props.job.description && (
                <ListItemText secondary={props.job.description} />
              )}
              <ListItemText
                secondary={durationFormatter.format(props.job.duration)}
              />
            </>
          }
        />
      </ListItemButton>
      <EditJobDialog
        open={updateDialogOpen}
        worksites={getSelectableWorksites(props.worksites)}
        job={props.job}
        onClose={() => setUpdateDialogOpen(false)}
        onConfirm={props.onUpdate}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Supprimer un enregistrement"
        content="Voulez-vous supprimer cet enregistrement ?"
        cancelText="Annuler"
        confirmText="Supprimer"
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => props.onDelete(props.job.id)}
      />
    </ListItem>
  );
}
