import { Job } from "./job";
import { WithRequired } from "./types";

export type Worksite = {
  id: number;
  number: string;
  name: string;
  type: keyof typeof WorksiteType;
  state?: keyof typeof WorksiteState;
  targetTime?: number | null;
  /**
   * The total time worked on the worksite
   */
  totalTime?: number;
  /**
   * The total time worked on the worksite for the current year
   */
  totalTimeYear?: number;

  address?: string;
  location?: string;

  isDescriptionRequired?: boolean;
  isReferenceNumberRequired?: boolean;
  isAddressRequired?: boolean;
  isVisible?: boolean;
};

export type JobTypeWorksite = WithRequired<
  Worksite,
  | "isDescriptionRequired"
  | "isReferenceNumberRequired"
  | "isAddressRequired"
  | "isVisible"
> & {
  type: typeof WorksiteType.ACTIVITY;
};

export type JobTypeWorksiteWithTotalTime = WithRequired<
  JobTypeWorksite,
  "totalTime"
>;

export type WorksiteWithJobs = Worksite & {
  jobs: Job[];
};

export const WorksiteType = {
  WORKSITE: "WORKSITE",
  ACTIVITY: "ACTIVITY",
} as const;

export const WorksiteState = {
  PLANNED: "PLANNED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
} as const;

export const WorksiteStates = [
  WorksiteState.PLANNED,
  WorksiteState.IN_PROGRESS,
  WorksiteState.COMPLETED,
] as const;

const WorkSiteStateText = {
  [WorksiteState.IN_PROGRESS]: "En cours",
  [WorksiteState.PLANNED]: "Planifié",
  [WorksiteState.COMPLETED]: "Terminé",
} as const;

export const getWorksiteStateText = (state: keyof typeof WorksiteState) => {
  return WorkSiteStateText[state];
};

export const getWorksiteName = (
  worksite: Pick<Worksite, "number" | "name">
) => {
  return `[${worksite.number}] - ${worksite.name}`;
};

export const getSelectableWorksites = (worksites: Worksite[]) => {
  return worksites.filter(
    (worksite) =>
      worksite.state === WorksiteState.IN_PROGRESS ||
      worksite.type === WorksiteType.ACTIVITY
  );
};
