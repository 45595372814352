import { useState } from "react";
import { Button, List, Typography } from "@mui/material";
import { format } from "date-fns";
import { frCH } from "date-fns/locale";
import { Box } from "@mui/system";

import { CreateJobDialog } from "./CreateJobDialog";
import { JobListItem } from "./JobListItem";
import { getWorksiteName } from "src/lib/worksite";
import { useWorksites } from "src/queries/useWorksites";

import { useUserJobs } from "src/queries/useUserJobs";
import { Dialog } from "src/ui/Dialog";
import { UpdateJobSchema } from "src/schema/job.schema";

type SingleDayJobDialogProps = {
  date: Date;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  open: boolean;
  onCreateJob: (data: {
    date: Date;
    duration: string;
    description?: string;
    userId: number;
    worksiteId: number;
  }) => void;
  onUpdateJob: (data: UpdateJobSchema) => void;
  onDeleteJob: (id: number) => void;
  onClose: () => void;
};

export function SingleDayJobDialog(props: SingleDayJobDialogProps) {
  const [createJobOpen, setCreateJobOpen] = useState(false);

  const { data: worksites } = useWorksites();
  const { data: jobs } = useUserJobs({
    userId: props.user.id,
    start: props.date,
    end: props.date,
  });

  if (!jobs || !worksites) return null;

  return (
    <Dialog
      open={props.open}
      title={
        <Box display="flex">
          {`${props.user.firstName} ${props.user.lastName} le `}
          {format(new Date(props.date), "dd MMMM", {
            locale: frCH,
          })}
          <Box sx={{ ml: 2 }}>
            <Button variant="outlined" onClick={() => setCreateJobOpen(true)}>
              Créer
            </Button>
            <CreateJobDialog
              open={createJobOpen}
              date={new Date(props.date)}
              dateDisabled
              worksites={worksites}
              onClose={() => setCreateJobOpen(false)}
              onConfirm={(data) =>
                props.onCreateJob({
                  ...data,
                  date: new Date(props.date),
                  userId: props.user.id,
                })
              }
            />
          </Box>
        </Box>
      }
      cancelText="Fermer"
      onClose={props.onClose}
      onCancel={props.onClose}
      noConfirm
    >
      <List disablePadding sx={{ flex: 1 }}>
        {jobs.map((job) => (
          <JobListItem
            key={job.id}
            job={job}
            primary={getWorksiteName(job.worksite)}
            worksites={worksites}
            onUpdate={props.onUpdateJob}
            onDelete={props.onDeleteJob}
          />
        ))}
        {jobs.length === 0 && (
          <Typography variant="body1" textAlign="center" py={2}>
            Aucun enregistrement pour cette journée
          </Typography>
        )}
      </List>
    </Dialog>
  );
}
