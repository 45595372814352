import { useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  updateWorksiteSchema,
  UpdateWorksiteSchema,
} from "src/schema/worksite.schema";
import { Worksite } from "src/lib/worksite";
import { Dialog } from "src/ui/Dialog";
import { pick } from "src/lib/util";

type EditJobTypeDialogProps = {
  open: boolean;
  jobType: Worksite;
  onClose: () => void;
  onConfirm: (jobType: UpdateWorksiteSchema) => void;
};

const FormValues = [
  "id",
  "number",
  "name",
  "isDescriptionRequired",
  "isReferenceNumberRequired",
  "isAddressRequired",
  "isVisible",
] as const;

export function EditJobTypeDialog(props: EditJobTypeDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateWorksiteSchema>({
    resolver: zodResolver(updateWorksiteSchema),
    defaultValues: pick(props.jobType, FormValues),
  });

  useEffect(() => {
    reset(pick(props.jobType, FormValues));
  }, [reset, props.jobType]);

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: UpdateWorksiteSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title={"Modifier un type de tâche"}
      cancelText="Annuler"
      confirmText="Modifier"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="number"
        autoFocus
        margin="dense"
        label="N°"
        fullWidth
        variant="outlined"
        defaultValue={props.jobType.number}
        inputProps={register("number", { required: true })}
        error={!!errors.number}
        helperText={errors.number?.message ?? " "}
      />
      <TextField
        id="name"
        margin="normal"
        label="Nom"
        fullWidth
        variant="outlined"
        defaultValue={props.jobType.name}
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isDescriptionRequired"
              control={control}
              defaultValue={props.jobType.isDescriptionRequired}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Description"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isReferenceNumberRequired"
              control={control}
              defaultValue={props.jobType.isReferenceNumberRequired}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Numéro de chantier"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isAddressRequired"
              control={control}
              defaultValue={props.jobType.isAddressRequired}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Adresse de chantier"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isVisible"
              control={control}
              defaultValue={props.jobType.isVisible}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Visible par les employés"
        />
      </FormGroup>
    </Dialog>
  );
}
