import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { UserJobsQueryKey } from "./useUserJobs";
import { WorksiteJobsQueryKey } from "./useWorksiteJobs";
import { durationParser } from "src/lib/intl";
import { WorksiteQueryKey } from "./useWorksite";
import { JobsQueryKey } from "./useJobs";

type UseUpdateJobOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseUpdateJobParams = { id: number } & Partial<{
  duration: string;
  description: string | null;
  referenceNumber: string | null;
  address: string | null;
  worksiteId: number;
}>;

export function useUpdateJob(options: UseUpdateJobOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseUpdateJobParams, Error, UseUpdateJobParams>(
    async ({ id, ...params }: UseUpdateJobParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/jobs/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(
            snakecaseKeys(
              {
                ...params,
                duration: params.duration
                  ? durationParser.parse(params.duration)
                  : undefined,
              },
              { deep: true }
            )
          ),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update job.");
      }

      const data = await response.json();
      return camelcaseKeys(data.job, { deep: true });
    },
    {
      ...options,
      onSuccess: (data) => {
        // invalidate /users/:id/jobs
        queryClient.invalidateQueries(UserJobsQueryKey);
        // invalidate /worksite/:id/jobs
        queryClient.invalidateQueries(WorksiteJobsQueryKey);
        // invalidate /worksite/:id
        queryClient.invalidateQueries([WorksiteQueryKey, data.worksiteId]);
        // invalidate /jobs
        queryClient.invalidateQueries([JobsQueryKey]);

        options.onSuccess?.();
      },
    }
  );
}
